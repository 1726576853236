.App {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.no-margin {
    margin: 0;
    padding: 0;
}

.year-month-select-div {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
      }

.location-select-div {
        position: absolute;
        top: 0;
        right: 200px;
        z-index: 100;
        margin: 20px;
      }

.selector {
    background-color: #ffffff;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.mapboxgl-ctrl-bottom-right {
    display: none !important;
}
